
import Router from '../../../routes/Router'

const MainWrapper = () => {
	
	
	return <div >
			
				<Router />		
			
		</div>
}


export default MainWrapper


