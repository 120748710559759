import HomePage from '../pages/HomePage'


enum PAGES{
	HOME,

}

interface IRouteDescription{
	path:string;
	component:any;
}


const Routes:IRouteDescription[] = [
	{ path: "/", component: HomePage }
]

export default Routes;