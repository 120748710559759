
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

import routes from './routes'

import 'bootstrap/dist/css/bootstrap.css';

import Header from '../layouts/t-dark-classic/components/Header'
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from 'react-bootstrap';
import Footer from '../layouts/t-dark-classic/components/Footer';

export default function Router() {
  return (
    <BrowserRouter basename={"/"}>
        <div >
			    <Header/>
        </div>
      <div>
        <ToastContainer closeButton={true}  />
      </div>
      <ThemeProvider >
          <div style={{'textAlign':'justify','marginLeft':'5%','marginRight':'5%','marginTop':'1%'}}>
            <Switch>
              {routes.map((r)=>{
                return <Route exact path={r.path} component={r.component}/>
              })}
            </Switch>
            </div>
        </ThemeProvider>
      <Footer/>
    </BrowserRouter>
  );
}