
import './App.css';
import MainWrapper from './layouts/t-dark-classic/components/MainWrapper';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { theme } from './layouts/t-dark-classic/styles/style';

function App() {
	toast.error({
		draggable: true,
		pauseOnHover: true,
		
	});
	toast.success({
		autoClose: 10000,
		draggable: true,
		pauseOnHover: true,
		
	});
	
	useEffect(() => {
		document.body.className = theme.bodyClass
	},[])

	return (
		 <>
	  <Helmet>
		<meta charSet="utf-8" />
		<title>Nyctomancia</title>
		<link rel="icon" type="image/svg" href="/TC.svg" />
		  </Helmet>
		  
			<MainWrapper />
			</>
		
    
  );
}

export default App;
