
import { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
        position: 'fixed',
        width: '100%',
        bottom: '0',
        textAlign: 'center',
        fontSize:12
    },
  }),
);

export default function Footer() {
  const classes = useStyles();

  useEffect(() => {
    
  },[])

  

  return (
    <div className={classes.footer}>
        v{""+process.env.REACT_APP_VERSION} {""+process.env.REACT_APP_ENV} - Website by Tzernak (contact: nyctomancia@gmail.com)
    </div>
  );
}