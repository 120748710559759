import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import qs from "qs";
import { toast } from "react-toastify";

const HomePage = (props: { location: { search: string; }; })=>{

	useEffect(()=>{
		if (qs.parse(props.location.search, { ignoreQueryPrefix: true }).not_identified) {
			toast.warning("Vous devez vous connecter pour accéder à cette page.")
		}
	},[])

	return (<>
		<h1>Nyctomancia</h1>
		<p>Nyctomancia's website</p>

</>)
}

export default HomePage;