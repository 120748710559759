import { createContext, DetailedHTMLProps, HtmlHTMLAttributes } from "react"



export const style = {
	
	
	
	header: {
		backgroundColor: "grey",
		position:'fixed',
		width:'100%',
		height:'3em',
	},

	
	
}



interface Theme{
	bodyClass: string,
	bg:string,
	bgSec: string,
	
	selectStyle:any,
}

const defaultTheme: Theme = {
	bodyClass: "",
	bg:"",
	bgSec: "",
	selectStyle: {}
}

const darkTheme: Theme = {
	bodyClass: "bg-dark text-white",
	bg:"bg-dark",
	bgSec: "bg-secondary",
	selectStyle:{
		option: (provided:any, state:any) => ({
		  ...provided,
			color: state.isSelected ? 'red' : 'black',
		  backgroundColor:'rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) '
		}),
		placeholder: (provided:any, state:any) => ({
			...provided,
			  color: 'white',
			backgroundColor:'rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) '
		  }),
		singleValue: (provided:any, state:any) => {
		  const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			const color= 'white';
	  
		  return { ...provided, opacity, transition, color };
		},		
		control: () => ({
			height:'40px'
		}),
		dropdownIndicator: () => ({
			display:'none'
		}),
	  }
}

export const theme: Theme = darkTheme;



