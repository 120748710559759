
import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      backgroundColor: 'inherit',
      color:'grey',
      textAlign: 'right',
      float:'right',
      fontSize:12
    },
    title: {
      display: 'block',
      fontWeight: 'bold',
      paddingLeft: '1em',
      paddingRight: '2em',
    },
    sectionDesktop: {
      display: 'flex',
      textAlign: 'right'
     
    },
    selected:{
      color:'white',
    }
  }),
);

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const [pathSelected, setPathSelected] = React.useState<string>("");
  
  const handleGoTo = (path:string) => {
    history.push(path);
    setPathSelected(path);
    
  };

  useEffect(() => {
    
  },[])

  

  return (
    <div >
      <AppBar position="static" style={{ background: 'black', width: '100%' }}>
        
        <Toolbar style={{ width: '100%' }}>
          <img hidden={true} onClick={() => handleGoTo("/")} src={window.location.origin + '/favicon.ico'} />
          <Typography className={classes.title} variant="h5" noWrap onClick={()=>handleGoTo("/")}>
            Nyctomancia
          </Typography>
          <Button className={classes.menuButton + " " + (pathSelected === "/overview" ? classes.selected : "")} onClick={() => handleGoTo("/overview")} >Menu</Button>
                   
          
        </Toolbar>
        
      </AppBar>
    </div>
  );
}